<template>
  <div class="home">
    <div class="home-bg">
      <h2>星云文档，随时随地，享受协同的乐趣</h2>
      <p>支持多人同时在线编辑，安全可靠，随时随地满足您的需求</p>
      <img src="@/assets/about/banner.png" alt="banner" />
    </div>
    <div class="white-wrap experience flex">
      <div class="experience-item" v-for="item in experience" :key="item.title">
        <img :src="item.img" alt="" />
        <h3>{{ item.title }}</h3>
        <div class="description flex-column">
          <p v-for="p in item.description" :key="p">{{ p }}</p>
        </div>
        <button @click="handleExperience(item.type)">立即体验</button>
      </div>
    </div>
    <div class="introduce undertint-wrap">
      <div class="introduce-wrap flex-between-center">
        <img src="@/assets/about/cooperation.png" alt="cooperation" />
        <div class="introduce-content">
          <h2>团队协作</h2>
          <p>
            支持团队成员在线协作，信息实时同步，轻松完成信息收集与共享，再也不用单独文件发来发去，避免繁琐工作，方便你我他。
          </p>
        </div>
      </div>
    </div>
    <div class="introduce white-wrap">
      <div class="introduce-wrap multiport">
        <div class="introduce-content">
          <h2>多端同步</h2>
          <p>
            小程序端和PC端数据同步实时更新，再也不用担心因文件数据不同步造成的工作失误；
          </p>
        </div>
        <img class="star-mac" src="@/assets/about/star-mac.png" alt="star-mac" />
        <img
          class="star-iPhone"
          src="@/assets/about/star-iPhone.png"
          alt="star-iPhone"
        />
      </div>
    </div>
    <div class="introduce undertint-wrap">
      <div class="introduce-wrap flex-between-center">
        <img src="@/assets/about/role.png" alt="role" />
        <div class="introduce-content">
          <h2>权限可控</h2>
          <p>
            管理员可根据实际需要，给团队成员分配不同的权限，如张三仅需要查看文件，那么可以给其分配“查看”权限，李四需要编辑文件，那么可以给其分配“编辑”权限，整体权限可控，文件安全有保障
          </p>
        </div>
      </div>
    </div>
    <div class="introduce white-wrap">
      <div class="introduce-wrap flex-between-center">
        <div class="introduce-content">
          <h2>后管平台</h2>
          <p>
            后管平台可以随时查看用户数据、文件类型分布以及容量使用情况，及时掌握用户动态，同时还有客户管理、任务管理、等级管理、公告设置、反馈中心、系统管理等6大功能，真正做到功能齐全，确保用户用的省心；
          </p>
        </div>
        <img src="@/assets/about/admin.png" alt="admin" />
      </div>
    </div>
    <div class="advantage flex-column">
      <h2>我们的优势</h2>
      <div class="advantage-wrap flex">
        <div
          class="advantage-item flex-column"
          v-for="item in advantage"
          :key="item.title"
        >
          <img :src="item.img" alt="" />
          <h3>{{ item.title }}</h3>
          <p>{{ item.description }}</p>
        </div>
      </div>
    </div>
    <div class="app flex">
      <div class="qr-code flex-column-between-center">
        <img src="@/assets/about/app-qr-code.jpg" alt="app-qr-code" />
        <span>“扫码体验星云小程序”</span>
      </div>
      <img class="arrows" src="@/assets/about/arrows.png" alt="arrows" />
      <img class="app-img" src="@/assets/about/star-app.png" alt="star-app" />
      <div class="description">
        <h2>星云小程序</h2>
        <p>支持多人同时在线编辑，安全可靠， 随时随地满足您的需求</p>
      </div>
    </div>
  </div>
</template>

<script>
import experience from "./json/experience.js";
import advantage from "./json/advantage.js";

export default {
  name: "home",
  components: {},
  props: {},
  data() {
    return {
      experience,
      advantage,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    handleExperience(type) {
      window.open(`https://star.csii.com.cn/#/user/login?visitor=1&type=${type}`);
      // window.open(`http://172.30.15.86:8080/#/user/login?visitor=1&type=${type}`);
    },
  },
};
</script>

<style scoped lang="less">
.white-wrap {
  background-color: #ffffff;
}
.undertint-wrap {
  background-color: #f5f7fa;
}
.default-width {
  width: 1200px;
  margin: 0 auto;
}
.home {
  margin-top: -60px;
  .home-bg {
    width: 100%;
    min-width: 1200px;
    height: 978px;
    background: url(~@/assets/about/bg.png) no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-size: 42px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      margin-top: 156px;
      margin-bottom: 24px;
    }
    p {
      font-size: 22px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      margin-bottom: 108px;
    }
    img {
      width: 860px;
      box-shadow: 10px 10px 50px 0 rgba(160, 187, 210, 0.63);
    }
  }
  .experience {
    .default-width();
    height: 781px;
    justify-content: space-between;
    .experience-item {
      margin-top: 27px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 397px;
        height: 380px;
        margin-bottom: 28px;
      }
      h3 {
        font-size: 28px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 600;
        color: #333333;
        margin-bottom: 16px;
      }
      .description {
        height: 128px;
        align-items: center;
      }
      p {
        font-size: 18px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
      }
      button {
        width: 192px;
        height: 48px;
        line-height: 48px;
        border-radius: 8px;
        border: 1px solid #4a61ff;
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #4a61ff;
        background: #ffffff;
        cursor: pointer;
      }
      button:hover {
        background: linear-gradient(to right, #708aff, #4a61ff);
        color: #ffffff;
        opacity: 1;
      }
      button:active {
        opacity: 0.9;
      }
    }
  }
  .introduce {
    .introduce-wrap {
      height: 720px;
      .default-width();
      img {
        width: 774px;
      }
    }
    .multiport {
      display: flex;
      align-items: center;
      position: relative;
      .star-mac {
        position: absolute;
        width: 1045px;
        top: 12px;
        right: -46px;
      }
      .star-iPhone {
        position: absolute;
        width: 317px;
        bottom: 12px;
        right: -46px;
        z-index: 1;
      }
    }
    .introduce-content {
      width: 360px;
      h2 {
        font-size: 42px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #4a61ff;
        margin-block: 32px;
      }
      p {
        font-size: 22px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
      }
    }
  }
  .advantage {
    width: 100%;
    min-width: 1200px;
    height: 560px;
    background: url(~@/assets/about/bg2.png) no-repeat;
    background-size: 100% 100%;
    align-items: center;
    h2 {
      font-size: 40px;
      height: 40px;
      line-height: 40px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      margin-top: 79px;
    }
    .advantage-wrap {
      .default-width();
      justify-content: space-between;
      margin-top: 73px;
      .advantage-item {
        width: 270px;
        align-items: center;
        img {
          margin-bottom: 50px;
          width: 96px;
        }
        h3 {
          margin-bottom: 16px;
          font-size: 28px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #ffffff;
        }
        p {
          font-size: 18px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }
  .app {
    .default-width();
    height: 880px;
    position: relative;
    .qr-code {
      box-sizing: border-box;
      width: 312px;
      height: 312px;
      padding: 26px;
      position: absolute;
      left: 0;
      top: 360px;
      box-shadow: 11px 11px 55px 0px rgba(194,207,218,0.3);
      border-radius: 24px;
      img {
        width: 208px;
        height: 208px;
      }
    }
    .arrows {
      position: absolute;
      width: 191px;
      height: 217px;
      top: 112px;
      left: 168px;
    }
    .app-img {
      position: absolute;
      width: 500px;
      top: 0;
      left: 317px;
    }
    .description {
      position: absolute;
      width: 360px;
      top: 342px;
      right: 13px;
      h2 {
        font-size: 42px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #4a61ff;
        margin-block: 32px;
      }
      p {
        font-size: 22px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>
