export default [
    {
        img: require('@/assets/about/doc.png'),
        title: '文档',
        description: [
            '彻底告别无纸化办公，多端同步，在线协同，',
            '编辑页面功能完善，无论是样式、',
            '布局或超链接等，均可支持；'
        ],
        type:'docx'
    },
    {
        img: require('@/assets/about/excel.png'),
        title: '表格',
        description: [
            '可多人在线协同处理同一张表格，',
            '数据实时更新，导入导出无任何障碍；',
            '函数公式10大类，覆盖各种应用场景，',
            '让数据处理起来更顺畅，更高效；'
        ],
        type:'xlsx'
    },
    {
        img: require('@/assets/about/ppt.png'),
        title: '幻灯片',
        description: [
            '支持本地文件上传，高度兼容各种PPT格式，',
            '随时随地在线修改编辑，同时支持在线演示，',
            '可进行演讲者视图切换；'
        ],
        type:'pptx'
    },
]