export default [
    {
        img: require('@/assets/about/icon3.png'),
        title: '支持私有网络部署',
        description: '金融科技领域往往采用网络隔离手段从物理上保障其数据安全性和保密性，以实惠的购置成本享受海量级存储的服务。'
    },
    {
        img: require('@/assets/about/icon1.png'),
        title: '金融级文件存储',
        description: '特别适合那些对数据故障恢复和灾难恢复能力要求极高的金融科技领域，如：银行、证券、保险等。'
    },
    {
        img: require('@/assets/about/icon2.png'),
        title: '支持高度定制化开发',
        description: '采用业内先进的分布式架构，技术完全自主可控，为金融科技领域的个性化需求提供定制化的存储解决方案。'
    },
]