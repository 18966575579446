var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_vm._m(0),_c('div',{staticClass:"white-wrap experience flex"},_vm._l((_vm.experience),function(item){return _c('div',{key:item.title,staticClass:"experience-item"},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('h3',[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"description flex-column"},_vm._l((item.description),function(p){return _c('p',{key:p},[_vm._v(_vm._s(p))])}),0),_c('button',{on:{"click":function($event){return _vm.handleExperience(item.type)}}},[_vm._v("立即体验")])])}),0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"advantage flex-column"},[_c('h2',[_vm._v("我们的优势")]),_c('div',{staticClass:"advantage-wrap flex"},_vm._l((_vm.advantage),function(item){return _c('div',{key:item.title,staticClass:"advantage-item flex-column"},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('h3',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.description))])])}),0)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-bg"},[_c('h2',[_vm._v("星云文档，随时随地，享受协同的乐趣")]),_c('p',[_vm._v("支持多人同时在线编辑，安全可靠，随时随地满足您的需求")]),_c('img',{attrs:{"src":require("@/assets/about/banner.png"),"alt":"banner"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduce undertint-wrap"},[_c('div',{staticClass:"introduce-wrap flex-between-center"},[_c('img',{attrs:{"src":require("@/assets/about/cooperation.png"),"alt":"cooperation"}}),_c('div',{staticClass:"introduce-content"},[_c('h2',[_vm._v("团队协作")]),_c('p',[_vm._v(" 支持团队成员在线协作，信息实时同步，轻松完成信息收集与共享，再也不用单独文件发来发去，避免繁琐工作，方便你我他。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduce white-wrap"},[_c('div',{staticClass:"introduce-wrap multiport"},[_c('div',{staticClass:"introduce-content"},[_c('h2',[_vm._v("多端同步")]),_c('p',[_vm._v(" 小程序端和PC端数据同步实时更新，再也不用担心因文件数据不同步造成的工作失误； ")])]),_c('img',{staticClass:"star-mac",attrs:{"src":require("@/assets/about/star-mac.png"),"alt":"star-mac"}}),_c('img',{staticClass:"star-iPhone",attrs:{"src":require("@/assets/about/star-iPhone.png"),"alt":"star-iPhone"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduce undertint-wrap"},[_c('div',{staticClass:"introduce-wrap flex-between-center"},[_c('img',{attrs:{"src":require("@/assets/about/role.png"),"alt":"role"}}),_c('div',{staticClass:"introduce-content"},[_c('h2',[_vm._v("权限可控")]),_c('p',[_vm._v(" 管理员可根据实际需要，给团队成员分配不同的权限，如张三仅需要查看文件，那么可以给其分配“查看”权限，李四需要编辑文件，那么可以给其分配“编辑”权限，整体权限可控，文件安全有保障 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduce white-wrap"},[_c('div',{staticClass:"introduce-wrap flex-between-center"},[_c('div',{staticClass:"introduce-content"},[_c('h2',[_vm._v("后管平台")]),_c('p',[_vm._v(" 后管平台可以随时查看用户数据、文件类型分布以及容量使用情况，及时掌握用户动态，同时还有客户管理、任务管理、等级管理、公告设置、反馈中心、系统管理等6大功能，真正做到功能齐全，确保用户用的省心； ")])]),_c('img',{attrs:{"src":require("@/assets/about/admin.png"),"alt":"admin"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app flex"},[_c('div',{staticClass:"qr-code flex-column-between-center"},[_c('img',{attrs:{"src":require("@/assets/about/app-qr-code.jpg"),"alt":"app-qr-code"}}),_c('span',[_vm._v("“扫码体验星云小程序”")])]),_c('img',{staticClass:"arrows",attrs:{"src":require("@/assets/about/arrows.png"),"alt":"arrows"}}),_c('img',{staticClass:"app-img",attrs:{"src":require("@/assets/about/star-app.png"),"alt":"star-app"}}),_c('div',{staticClass:"description"},[_c('h2',[_vm._v("星云小程序")]),_c('p',[_vm._v("支持多人同时在线编辑，安全可靠， 随时随地满足您的需求")])])])
}]

export { render, staticRenderFns }